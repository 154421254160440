import * as React from "react";
import * as ReactDOM from "react-dom";
import "./index.css";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

import App from "./App";
import PrivacyPolicy from "./Screens/PrivacyPolicy";
import GdprPolicy from "./Screens/GdprPolicy";

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
  },
  {
    path: "/privacy-policy",
    element: <PrivacyPolicy />,
  },
  {
    path: "/gdpr-policy",
    element: <GdprPolicy />,
  },
]);

ReactDOM.createRoot(document.getElementById("root")).render(
  <RouterProvider router={router} />
);
