import React from "react";
import Box from "@mui/material/Box";

import Modal from "@mui/material/Modal";
import { useState } from "react";
import { motion } from "framer-motion";
import axios from "axios";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 490,
  height: 520,
  bgcolor: "#0099dc",
  paddingBottom: "10px",
  borderRadius: 5,
  boxShadow: 24,
  p: 4,
};

const CateringSection = () => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [occasion, setOccasion] = useState("");
  const [date, setDate] = useState("");
  const [time, setTime] = useState("");
  const [submitted, setSubmitted] = useState(false);

  const [location, setLocation] = useState("");
  const [guests, setGuests] = useState("");
  const [comments, setComments] = useState("");

  async function handleSubmit() {
    try {
      await axios.post(`https://sheetdb.io/api/v1/guj7pzbva02vg`, {
        First: firstName,
        Last: lastName,
        Email: email,
        Mobile: mobile,
        Occasion: occasion,
        EventDate: date,
        EventTime: time,
        Location: location,
        Guests: guests,
        Comments: comments,
      });

      setSubmitted(true);
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <div className="md:pt-20  md:pb-0 pb-14 bg-tan" id="catering">
      <div>
        <div className="flex flex-col items-center md:pt-0 pt-24 ">
          <motion.h3
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{ delay: 0.4 }}
            viewport={{ once: true }}
            className="text-5xl font-bold uppercase font-Black text-center md:mt-10"
          >
            are you hosting an event?{" "}
          </motion.h3>
          <motion.div
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{ delay: 0.4 }}
            viewport={{ once: true }}
            className="md:mx-0 mx-6"
          >
            <img
              src="https://ik.imagekit.io/jsklle928/Howe%20and%20Co/382442526_339180288635022_816584607587015235_n%201.png?updatedAt=1696422449498"
              alt="food truck"
              className="mt-12 "
            />
          </motion.div>
          <div className="mt-10 md:text-3xl text-xl text-center w-5/6 md:leading-[3rem] leading-10">
            <motion.h4
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              transition={{ delay: 0.4 }}
              viewport={{ once: true }}
            >
              Why not reach out to us and see if we can make it even more
              special with our delicious fish and chips? With over 10 years of
              experience, we have catered for weddings, parties, beer festivals,
              village fetes, corporate lunches, and birthday parties.
            </motion.h4>
            <motion.h4
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              transition={{ delay: 0.4 }}
              viewport={{ once: true }}
              className="mt-10"
            >
              We understand the importance of a personalized touch, so we can
              provide you with a custom quote in less than 48 hours. Our team
              takes pride in delivering an amazing service that will leave your
              guests satisfied and craving for more.
            </motion.h4>
            <motion.button
              initial={{ y: 200, opacity: 0 }}
              whileInView={{ y: 0, opacity: 1 }}
              transition={{ delay: 0.2, type: "spring" }}
              viewport={{ once: true }}
              className="bg-customBlue md:px-32 px-4 py-8 md:text-4xl text-3xl font-bold font-Black uppercase tracking-wider text-tan rounded-lg mt-7"
              onClick={handleOpen}
            >
              event inquiry form
            </motion.button>
          </div>
        </div>
        <div className="flex items-center justify-center mt-10 md:flex-nowrap flex-wrap md:mx-0 mx-8">
          <motion.img
            initial={{ x: -200, opacity: 0 }}
            whileInView={{ x: 0, opacity: 1 }}
            transition={{ delay: 0.1, type: "spring" }}
            viewport={{ once: true }}
            src="https://ik.imagekit.io/jsklle928/Howe%20and%20Co/381365411_278508371788656_4867817641060943536_n%201.png?updatedAt=1696357098253"
            alt="smiling people"
            className="mx-4 my-4 rounded-md"
          />
          <motion.img
            initial={{ x: 200, opacity: 0 }}
            whileInView={{ x: 0, opacity: 1 }}
            transition={{ delay: 0.1, type: "spring" }}
            viewport={{ once: true }}
            src="https://ik.imagekit.io/jsklle928/Howe%20and%20Co/381381723_1502259343907204_236137200088407517_n%201.png?updatedAt=1696357098443"
            alt="smiling people eating"
            className="mx-4 my-4 rounded-md"
          />
        </div>
        <div className="flex flex-col items-center mt-10">
          <img
            src="https://ik.imagekit.io/jsklle928/Howe%20and%20Co/food%20standards.jpeg?updatedAt=1699488249767"
            alt="food standard agency badge"
            className="md:w-1/2 md:px-0 px-8 rounded-md"
          />
        </div>
      </div>
      <>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <div className="flex flex-col items-center overflow-scroll h-[450px]">
              {submitted ? (
                <div className="flex flex-col justify-center h-full">
                  <h2 className="md:text-4xl font-black uppercase text-center">
                    Success!
                  </h2>
                  <h3 className="md:text-2xl text-center">
                    Your information has successfully been submitted. We will be
                    in contact with you shortly!
                  </h3>
                </div>
              ) : (
                <div>
                  <h4 className="md:text-2xl text-xl md:mx-0 mx-2 text-center capitalize leading-snug font-bold md:px-0 px-4 md:pb-0 pb-4">
                    Join our mailing list to receive chip van updates, reminders
                    and exclusive discounts straight to your phone.{" "}
                  </h4>
                  <div className="flex md:flex-row flex-col items-center w-full justify-center md:mt-8 md:px-0 px-4">
                    <div className="md:w-1/2 w-full mx-2">
                      <h5 className="text-tan text-xl">First Name</h5>
                      <input
                        onChange={(e) => {
                          setFirstName(e.target.value);
                        }}
                        className="w-full mt-2 h-10 px-2 rounded-md bg-black bg-opacity-20 text-zinc-800 font-medium placeholder:text-zinc-800"
                        placeholder="Enter your First Name"
                      ></input>
                    </div>
                    <div className="md:w-1/2 w-full  mx-2 md:mt-0 mt-2">
                      <h5 className="text-tan text-xl">Last Name</h5>
                      <input
                        onChange={(e) => {
                          setLastName(e.target.value);
                        }}
                        className="w-full mt-2 h-10 px-2 rounded-md bg-black bg-opacity-20 text-zinc-800 font-medium placeholder:text-zinc-800 "
                        placeholder="Enter your Last Name"
                      ></input>
                    </div>
                  </div>

                  <div className="flex md:flex-row items-center flex-col w-full justify-center mt-4 md:px-0 px-4">
                    <div className="md:w-1/2 w-full mx-2">
                      <h5 className="text-tan text-xl">Email Address</h5>
                      <input
                        onChange={(e) => {
                          setEmail(e.target.value);
                        }}
                        className="w-full mt-2 h-10 px-2 rounded-md bg-black bg-opacity-20 text-zinc-800 font-medium placeholder:text-zinc-800"
                        placeholder="Enter your Email Address"
                      ></input>
                    </div>
                    <div className="md:w-1/2 w-full mx-2 md:mt-0 mt-2">
                      <h5 className="text-tan text-xl">Mobile Phone</h5>
                      <input
                        onChange={(e) => {
                          setMobile(e.target.value);
                        }}
                        className="w-full mt-2 h-10 px-2 rounded-md bg-black bg-opacity-20 text-zinc-800 font-medium placeholder:text-zinc-800 "
                        placeholder="Enter your Mobile Phone"
                      ></input>
                    </div>
                  </div>

                  <div className="flex md:flex-row items-center flex-col w-full justify-center mt-4 md:px-0 px-4">
                    <div className="md:w-full w-full mx-2">
                      <h5 className="text-tan text-xl">
                        Type/Occasion of Event
                      </h5>
                      <input
                        onChange={(e) => {
                          setOccasion(e.target.value);
                        }}
                        className="w-full mt-2 h-10 px-2 rounded-md bg-black bg-opacity-20 text-zinc-800 font-medium placeholder:text-zinc-800"
                        placeholder="Enter your Event Type"
                      ></input>
                    </div>
                  </div>

                  <div className="flex md:flex-row items-center flex-col w-full justify-center mt-4 md:px-0 px-4">
                    <div className="md:w-1/2 w-full mx-2">
                      <h5 className="text-tan text-xl">Date of Event</h5>
                      <input
                        onChange={(e) => {
                          setDate(e.target.value);
                        }}
                        className="w-full mt-2 h-10 px-2 rounded-md bg-black bg-opacity-20 text-zinc-800 font-medium placeholder:text-zinc-800"
                        placeholder="Enter Date of Event"
                      ></input>
                    </div>
                    <div className="md:w-1/2 w-full mx-2 md:mt-0 mt-2">
                      <h5 className="text-tan text-xl">Serving Time</h5>
                      <input
                        onChange={(e) => {
                          setTime(e.target.value);
                        }}
                        className="w-full mt-2 h-10 px-2 rounded-md bg-black bg-opacity-20 text-zinc-800 font-medium placeholder:text-zinc-800 "
                        placeholder="Enter the Serving Time"
                      ></input>
                    </div>
                  </div>

                  <div className="flex md:flex-row items-center flex-col w-full justify-center mt-4 md:px-0 px-4">
                    <div className="md:w-full w-full mx-2">
                      <h5 className="text-tan text-xl">Location of Event</h5>
                      <input
                        onChange={(e) => {
                          setLocation(e.target.value);
                        }}
                        className="w-full mt-2 h-10 px-2 rounded-md bg-black bg-opacity-20 text-zinc-800 font-medium placeholder:text-zinc-800"
                        placeholder="Enter Location of Event"
                      ></input>
                    </div>
                  </div>

                  <div className="flex md:flex-row items-center flex-col w-full justify-center mt-4 md:px-0 px-4">
                    <div className="md:w-full w-full mx-2">
                      <h5 className="text-tan text-xl">Approx # of Guests</h5>
                      <input
                        onChange={(e) => {
                          setGuests(e.target.value);
                        }}
                        className="w-full mt-2 h-10 px-2 rounded-md bg-black bg-opacity-20 text-zinc-800 font-medium placeholder:text-zinc-800"
                        placeholder="Enter Approx # of Guests"
                      ></input>
                    </div>
                  </div>

                  <div className="flex md:flex-row items-center flex-col w-full justify-center mt-4 md:px-0 px-4">
                    <div className="md:w-full w-full mx-2">
                      <h5 className="text-tan text-xl">
                        Comments, Dietary Requests, etc:
                      </h5>
                      <textarea
                        onChange={(e) => {
                          setComments(e.target.value);
                        }}
                        className="w-full mt-2  px-2 rounded-md bg-black bg-opacity-20 text-zinc-800 font-medium placeholder:text-zinc-800 h-20"
                        placeholder="Enter any additional comments"
                      ></textarea>
                    </div>
                  </div>

                  <div className="flex flex-col items-center">
                    <button
                      onClick={handleSubmit}
                      className="uppercase text-2xl text-white bg-black px-20 py-3 rounded-lg mt-6"
                    >
                      submit
                    </button>
                  </div>
                </div>
              )}
            </div>
          </Box>
        </Modal>
      </>
    </div>
  );
};

export default CateringSection;
