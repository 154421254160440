import React from "react";
import { useState } from "react";
import { Link } from "react-router-dom";

const MobileNavBar = () => {
  const [openMenu, setOpenMenu] = useState(false);
  function handleOpenMenu() {
    setOpenMenu(!openMenu);
  }
  return (
    <div className="fixed top-0 w-full bg-tan py-3 z-20">
      <div className="flex items-center w-full justify-between px-2">
        <div className="w-1/3">
          <button className="bg-blue text-tan px-3 py-2 rounded-md uppercase font-medium ">
            order now
          </button>
        </div>
        <div className="w-1/3 flex flex-col items-center">
          <Link to={"/"}>
            <img
              src={
                "https://ik.imagekit.io/jsklle928/Howe%20and%20Co/howe%20and%20co%20logo.jpeg?updatedAt=1699490079396"
              }
              alt="logo"
              className="w-32 ml-6"
            />
          </Link>
        </div>
        <div className="w-1/3 flex flex-col items-end" onClick={handleOpenMenu}>
          <div>
            {openMenu ? (
              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.8}
                  stroke="currentColor"
                  className="w-8 h-8"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </div>
            ) : (
              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.8}
                  stroke="currentColor"
                  className="w-8 h-8"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
                  />
                </svg>
              </div>
            )}
          </div>
        </div>
      </div>
      <>
        {openMenu && (
          <div className="bg-tan min-h-screen flex flex-col ">
            <div className="flex flex-col items-center  text-4xl uppercase space-y-8 mt-10 font-extrabold">
              {/* <h3
                onClick={() => {
                  const section = document.getElementById("tracker");
                  if (section) section.scrollIntoView({ behavior: "smooth" });
                  setOpenMenu(false);
                }}
              >
                chippy tracker
              </h3> */}
              {/* <h3>event tracker</h3> */}
              <h3
                onClick={() => {
                  const section = document.getElementById("chip-club");
                  if (section) section.scrollIntoView({ behavior: "smooth" });
                  setOpenMenu(false);
                }}
              >
                chip club
              </h3>
              <h3
                onClick={() => {
                  const section = document.getElementById("catering");
                  if (section) section.scrollIntoView({ behavior: "smooth" });
                  setOpenMenu(false);
                }}
              >
                catering
              </h3>
              <h3
                onClick={() => {
                  const section = document.getElementById("faq");
                  if (section) section.scrollIntoView({ behavior: "smooth" });
                  setOpenMenu(false);
                }}
              >
                FAQs
              </h3>
              <div>
                <h3 className="text-center">merch</h3>
                <h4 className="text-center text-sm">(coming soon)</h4>
              </div>

              <h3 className="text-center">
                📞 Van 22: <a href="tel:07376395678">07376395678</a>
              </h3>
              <h3 className="text-center ">
                📞 Van 55: <a href="tel:07494235366">07494235366</a>
              </h3>
            </div>
          </div>
        )}
      </>
    </div>
  );
};

export default MobileNavBar;
