import React from "react";
import NavBar from "../Components/NavBar";
import MobileNavBar from "../Components/MobileNavBar";
import Footer from "../Components/Footer";
import { Link } from "react-router-dom";

const PrivacyPolicy = () => {
  return (
    <div className="bg-customBlue min-h-screen pb-10">
      <div>
        <div className="md:visible invisible">
          <NavBar />
        </div>
        <div className="md:invisible visible">
          <MobileNavBar />
        </div>
      </div>
      <div className=" md:px-32 px-8" style={{ paddingTop: 130 }}>
        <h1 className="text-black uppercase text-4xl  font-bold text-center">
          Privacy Policy
        </h1>
        <div className=" mt-4">
          <h2 className="leading-10 md:text-lg">
            This privacy policy outlines how Howe & Co. Fish and Chips uses and
            protects any information that you provide when you use this website.
            Howe & Co. Fish and Chips is committed to ensuring that your privacy
            is protected. Should we ask you to provide certain information by
            which you can be identified when using this website, then you can be
            assured that it will only be used in accordance with this privacy
            statement. Howe & Co. Fish and Chips may update this policy from
            time to time by updating this page. You should check this page
            periodically to ensure that you are happy with any changes. This
            policy is effective from 22nd May 2018.
          </h2>
        </div>
        <div className="md:mt-8 mt-6">
          <h2 className="md:text-2xl text-xl uppercase font-bold ">
            what we collect
          </h2>
          <h2 className="leading-10 md:text-lg md:pt-2">
            We may collect the following information: Name and job title,
            Contact information including email address, Demographic information
            such as postcode, preferences, and interests Other information
            relevant to customer surveys and/or offers.
          </h2>
        </div>

        <div className="md:mt-8 mt-6">
          <h2 className="md:text-2xl  text-xl uppercase font-bold ">
            what we do with the information we gather
          </h2>
          <h2 className="leading-10 md:text-lg md:pt-2">
            We require this information to understand your needs and provide you
            with a better service, and in particular for the following reasons:
            Internal record keeping Improving our products and services Sending
            promotional emails about new products, special offers, or other
            information we think you may find interesting using the email
            address which you have provided Contacting you for market research
            purposes Customizing the website according to your interests.
          </h2>
        </div>

        <div className="md:mt-8 mt-6">
          <h2 className="md:text-2xl  text-xl uppercase font-bold ">
            security
          </h2>
          <h2 className="leading-10 md:text-lg md:pt-2">
            We are committed to ensuring that your information is secure. In
            order to prevent unauthorized access or disclosure, we have put in
            place suitable physical, electronic, and managerial procedures to
            safeguard and secure the information we collect online.
          </h2>
        </div>

        <div className="md:mt-8 mt-6">
          <h2 className="md:text-2xl  text-xl uppercase font-bold ">cookies</h2>
          <h2 className="leading-10 md:text-lg md:pt-2">
            A cookie is a small file that asks permission to be placed on your
            computer’s hard drive. Once you agree, the file is added and the
            cookie helps analyze web traffic or lets you know when you visit a
            particular site. Cookies allow web applications to respond to you as
            an individual. The web application can tailor its operations to your
            needs, likes, and dislikes by gathering and remembering information
            about your preferences. We use traffic log cookies to identify which
            pages are being used. This helps us analyze data about webpage
            traffic and improve our website to tailor it to customer needs. We
            only use this information for statistical analysis purposes and then
            the data is removed from the system. Overall, cookies help us
            provide you with a better website by enabling us to monitor which
            pages you find useful and which you do not. A cookie in no way gives
            us access to your computer or any information about you, other than
            the data you choose to share with us.
          </h2>
        </div>

        <div className="md:mt-8 mt-6">
          <h2 className="md:text-2xl  text-xl uppercase font-bold ">
            links to other websites
          </h2>
          <h2 className="leading-10 md:text-lg md:pt-2">
            Our website may contain links to other websites of interest.
            However, once you have used these links to leave our site, you
            should note that we do not have any control over that other website.
            Therefore, we cannot be responsible for the protection and privacy
            of any information which you provide whilst visiting such sites and
            such sites are not governed by this privacy statement. You should
            exercise caution and look at the privacy statement applicable to the
            website in question.
          </h2>
        </div>

        <div className="md:mt-8 mt-6">
          <h2 className="md:text-2xl  text-xl uppercase font-bold ">
            controlling your personal information
          </h2>
          <h2 className="leading-10 md:text-lg md:pt-2">
            We will not sell, distribute, or lease your personal information to
            third parties unless we have your permission or are required by law
            to do so. We may use your personal information to send you
            promotional information about third parties which we think you may
            find interesting if you tell us that you wish this to happen. You
            may request details of personal information which we hold about you
            under the Data Protection Act 1998. A small fee will be payable. If
            you would like a copy of the information held on you please email
            info@howeandcofishandchips.co.uk. If you believe that any
            information we are holding on you is incorrect or incomplete, please
            write to or email us as soon as possible at the above address. We
            will promptly correct any information found to be incorrect.
          </h2>
        </div>
      </div>
      <div>
        <Footer />
      </div>
      <div className="bg-customBlue flex  justify-center md:-mt-10 md:pb-8 pb-8">
        <Link
          to={"/privacy-policy"}
          className="hover:text-tan hover:cursor-pointer"
        >
          Privacy Policy
        </Link>
        <Link
          to={"/gdpr-policy"}
          className="hover:text-tan hover:cursor-pointer px-4"
        >
          GDPR Policy
        </Link>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
