import React from "react";

const Values2 = () => {
  return (
    <div className="flex flex-col items-center bg-customBlue w-full md:py-10 md:pb-20 pb-20 ">
      <div className="flex md:flex-row flex-col md:w-5/6 px-8 md:pt-20 pt-16 ">
        <div className="md:w-1/2  ">
          <img
            className="rounded-3xl"
            src="https://ik.imagekit.io/jsklle928/Howe%20and%20Co/fish%20image.jpeg?updatedAt=1698618309723"
            alt="food"
          />
        </div>
        <div className="md:w-1/2 md:mt-20 mt-4 md:text-left text-center md:px-10 md:pl-20 ">
          <h4 className="font-Black uppercase md:text-6xl text-5xl md:pt-0 pt-4">
            authenticity
          </h4>
          <h5 className="text-tan md:text-2xl text-2xl font-light md:leading-10 md:pt-0 pt-3">
            We embrace the tradition and heritage of fish and chips, staying
            true to the classic recipe that has delighted generations. Our
            commitment to authenticity means that our customers can enjoy a
            genuine taste of this beloved British dish.{" "}
          </h5>
        </div>
      </div>

      <div className="flex md:flex-row md:flew-nowrap flex-wrap-reverse md:w-5/6 px-8 md:mt-20 mt-8 ">
        <div className="md:w-1/2 md:mt-20 mt-4 md:text-left text-center md:px-10 md:pr-20 ">
          <h4 className="font-Black uppercase md:text-6xl text-5xl md:pt-0 pt-4">
            outstanding service
          </h4>
          <h5 className="text-tan md:text-2xl text-2xl font-light md:leading-10 md:pt-0 pt-3">
            We prioritize our customers and go above and beyond to provide
            exceptional service. We believe in treating everyone with warmth,
            respect, and a friendly smile, creating a welcoming and enjoyable
            experience for all.
          </h5>
        </div>
        <div className="md:w-1/2 md:pt-0 pt-6 ">
          <img
            src="https://ik.imagekit.io/jsklle928/Howe%20and%20Co/image%2012.png?updatedAt=1696357098612"
            alt="food"
          />
        </div>
      </div>

      <div className="flex md:flex-row flex-col md:w-5/6 px-8 md:mt-20 mt-8 ">
        <div className="md:w-1/2 md:pt-0 pt-6 ">
          <img
            src="https://ik.imagekit.io/jsklle928/Howe%20and%20Co/image%2011.png?updatedAt=1696357098363"
            alt="food"
          />
        </div>
        <div className="md:w-1/2 md:mt-20 mt-4 md:text-left text-center md:px-10 md:pl-20 ">
          <h4 className="font-Black uppercase md:text-6xl text-5xl md:pt-0 pt-4">
            community engagement
          </h4>
          <h5 className="text-tan md:text-2xl text-2xl font-light md:leading-10 md:pt-0 pt-3">
            We actively engage with and support the local community. Whether
            it's participating in community events, or giving back through
            charitable initiatives, we aim to be a positive force in the areas
            we serve.
          </h5>
        </div>
      </div>
    </div>
  );
};

export default Values2;
