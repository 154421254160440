import React from "react";
import { motion } from "framer-motion";

const Values = () => {
  return (
    <div className="flex flex-col items-center bg-customBlue   ">
      <div className="bg-customBlue w-full flex md:flex-row flex-col  items-center pb-8 pt-20 px-8  ">
        <motion.div
          initial={{ x: 200, opacity: 0 }}
          whileInView={{ x: 0, opacity: 1 }}
          transition={{ type: "spring", delay: 0.2 }}
          viewport={{ once: true }}
          className="md:w-1/2 w-full"
        >
          <div className="flex flex-col items-center">
            <img
              src={
                "https://ik.imagekit.io/jsklle928/Howe%20and%20Co/image%206.png?updatedAt=1696357099120"
              }
              alt="fish food"
              className=""
            />
          </div>
        </motion.div>
        <motion.div
          initial={{ x: -200, opacity: 0 }}
          whileInView={{ x: 0, opacity: 1 }}
          transition={{ type: "spring", delay: 0.2 }}
          viewport={{ once: true }}
          className="md:w-1/2  flex flex-col items-center md:flex-nowrap flex-wrap"
        >
          <div className="md:w-3/4 flex flex-col md:items-start items-center md:fex md:flex-nowrap flex-wrap md:mt-0 mt-6 md:text-left text-center">
            <h4 className="font-Black text-6xl uppercase md:mt-8">
              quality is everything
            </h4>
            <h5 className="md:text-2xl text-2xl  text-tan font-light md:leading-normal leading-normal md:pt-2">
              We value the importance of using only the freshest and
              highest-quality ingredients in all our dishes. We take pride in
              serving fish and chips that are cooked to perfection, ensuring a
              delightful experience for our customers.{" "}
            </h5>
          </div>
          <div className="flex flex-col items-center mt-4">
            <motion.button
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              transition={{ delay: 0.4 }}
              viewport={{ once: true }}
              className="bg-black text-lg uppercase md:px-8 px-6 md:py-3 py-2 rounded-lg text-tan font-medium"
            >
              chip van merch
            </motion.button>
            <h4 className="text-2xl uppercase mt-2 opacity-50">
              (coming soon)
            </h4>
          </div>
        </motion.div>
      </div>
      <motion.div
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        transition={{ delay: 0.4 }}
        viewport={{ once: true }}
        className="md:mt-16 w-full flex flex-col items-center pb-20"
      >
        <img
          src="
          
          https://ik.imagekit.io/jsklle928/Howe%20and%20Co/image%205.png?updatedAt=1696357098618"
          alt="food"
          className="md:w-3/4 md:px-0 px-6 "
        />
      </motion.div>
    </div>
  );
};

export default Values;
