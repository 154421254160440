import React from "react";
import { useState } from "react";
import { motion } from "framer-motion";

const faqData = [
  {
    id: 1,
    question: "How can I find the chip van?",
    answer:
      "Simple! Select either Van 22 or Van 55's chippy tracker and it will show you a map of where we are. You will also see the villages and roads we visit listed",
  },
  {
    id: 2,
    question: "Can I pay with a card? ",
    answer: "Absolutely YES! We accept both cash and card.",
  },
  {
    id: 3,
    question: "Do you do event catering?",
    answer:
      "We do indeed! Fill out the event form and tell us more about what you need. We'll get back to you in less than 48 hours with a bespoke quote.",
  },
  {
    id: 4,
    question: "Do I have to pre-order?",
    answer: "No. Simply arrive at the van, order and enjoy!",
  },
  {
    id: 5,
    question: "Do you offer Vegan, Halal or Gluten free? ",
    answer:
      "We're afraid we don't, because of our limited space we cannot offer any of these options.",
  },
];

const Faq = () => {
  const [open, setOpen] = useState(false);
  const [currentId, setCurrentId] = useState("");

  function handleOpen(id) {
    setOpen(!open);
    setCurrentId(id);
  }
  return (
    <div
      className="bg-tan flex flex-col items-center justify-center md:pt-20 pt-2 pb-48 "
      id="faq"
    >
      <div>
        <h3 className="text-8xl font-Black">FAQs</h3>
      </div>
      <motion.div
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        transition={{ delay: 0.1 }}
        viewport={{ once: true }}
        className="mt-4 flex flex-col items-center  "
      >
        {faqData.map((q) => (
          <div
            className="flex  items-center mt-10"
            key={q.id}
            onClick={() => {
              handleOpen(q.id);
            }}
          >
            <div className="flex flex-col items-center   ">
              <div className="flex items-center  ">
                <h4 className="text-customBlue md:text-5xl text-2xl text-center font-medium hover:cursor-pointer capitalize">
                  {q.question}
                </h4>
                <div className="hover:cursor-pointer"></div>
              </div>
              {open && currentId === q.id && (
                <h4 className="md:text-4xl font-medium mt-3 text-center md:leading-[3rem] md:px-0 px-4 md:w-3/4">
                  {q.answer}
                </h4>
              )}
            </div>
          </div>
        ))}
      </motion.div>
    </div>
  );
};

export default Faq;
