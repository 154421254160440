import React from "react";
import {
  Menu,
  MenuHandler,
  MenuList,
  MenuItem,
} from "@material-tailwind/react";
import { Link } from "react-router-dom";

const NavBar = () => {
  return (
    <div className="">
      <div className="flex w-full justify-between items-center bg-[#FCF1DD] px-6 py-2 fixed top-0 z-40">
        <div>
          <Link to={"/"}>
            <img
              src={
                "https://ik.imagekit.io/jsklle928/Howe%20and%20Co/howe%20and%20co%20logo.jpeg?updatedAt=1699490079396"
              }
              alt="logo"
              className="w-40"
            />
          </Link>
        </div>
        <div className="capitalize flex w-2/4 justify-around text-lg font-medium">
          <Menu>
            <div>
              <MenuHandler>
                <h3 className="hover:cursor-pointer hover:text-customBlue">
                  chippy tracker
                </h3>
              </MenuHandler>
              <MenuList className="bg-tan z-10 mt-6 text-lg ">
                <a
                  href="https://liveshare.ramtracking.com/?token=fda24a56-c44f-4871-9fc6-a0d99297d455"
                  target="blank"
                >
                  <MenuItem className="text-black">Track Van 22</MenuItem>
                </a>

                <a
                  href="https://liveshare.ramtracking.com/?token=ce090351-d2cc-4783-89d7-3365424e0ffc"
                  target="blank"
                >
                  <MenuItem className="text-black">Track Van 55</MenuItem>
                </a>
              </MenuList>
            </div>
          </Menu>

          {/* <h3 className="hover:cursor-pointer hover:text-customBlue">
            event tracker
          </h3> */}

          <h3
            className="hover:cursor-pointer hover:text-customBlue"
            onClick={() => {
              const section = document.getElementById("chip-club");
              if (section) section.scrollIntoView({ behavior: "smooth" });
            }}
          >
            chip club
          </h3>
          <h3
            className="hover:cursor-pointer hover:text-customBlue"
            onClick={() => {
              const section = document.getElementById("catering");
              if (section) section.scrollIntoView({ behavior: "smooth" });
            }}
          >
            catering
          </h3>

          <h3
            className="hover:cursor-pointer hover:text-customBlue"
            onClick={() => {
              const section = document.getElementById("faq");
              if (section) section.scrollIntoView({ behavior: "smooth" });
            }}
          >
            FAQs
          </h3>
          <h3 className="text-zinc-500">merch (coming soon)</h3>
        </div>
        <div className="md:invisible">
          <button className="bg-[#0099dc] text-white uppercase px-4 py-3 rounded-md font-bold text-lg hover:text-xl">
            place an order
          </button>
        </div>
      </div>
    </div>
  );
};

export default NavBar;
