import { Link } from "react-router-dom";
import CateringSection from "./Components/CateringSection";
import ChipClubCTA from "./Components/ChipClubCTA";
import Footer from "./Components/Footer";
import Header from "./Components/Header";
import MobileNavBar from "./Components/MobileNavBar";
import NavBar from "./Components/NavBar";
import CookieConsent from "react-cookie-consent";

import Values from "./Components/Values";
import Values2 from "./Components/Values2";
import Faq from "./Faq";

import { useEffect } from "react";

// function sendPixelData() {
//   const script = document.createElement("script");
//   script.async = true;
//   script.src = "https://connect.facebook.net/en_US/fbevents.js";
//   document.head.appendChild(script);

//   script.onload = () => {
//     window.fbq = function () {
//       window.fbq.callMethod
//         ? window.fbq.callMethod.apply(window.fbq, arguments)
//         : window.fbq.queue.push(arguments);
//     };
//     if (!window._fbq) window._fbq = window.fbq;
//     window.fbq.push = window.fbq;
//     window.fbq.loaded = true;
//     window.fbq.version = "2.0";
//     window.fbq.queue = [];

//     window.fbq("init", "1339010956737844");
//     window.fbq("track", "PageView");
//   };
// }

function App() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="overflow-hidden">
      <div>
        <div className="md:visible invisible">
          <NavBar />
        </div>
        <div className="md:invisible visible">
          <MobileNavBar />
        </div>
      </div>
      <div>
        <Header />
      </div>

      <div>
        <Values />
      </div>
      <div>
        <ChipClubCTA />
      </div>
      <div>
        <Values2 />
      </div>
      {/* <div>
        <TrackVans />
      </div> */}
      <div>
        <CateringSection />
      </div>
      <div>
        <Faq />
      </div>
      <div>
        <Footer />
      </div>
      <div className="bg-customBlue flex  justify-center md:-mt-10 md:pb-8 pb-8">
        <Link
          to={"/privacy-policy"}
          className="hover:text-tan hover:cursor-pointer px-2"
        >
          Privacy Policy
        </Link>
        <Link
          to={"/gdpr-policy"}
          className="hover:text-tan hover:cursor-pointer px-2"
        >
          GDPR Policy
        </Link>
        <a
          className="hover:text-tan hover:cursor-pointer px-2"
          href="https://designcafe.co"
          target="blank"
        >
          Site By DesignCafe
        </a>
      </div>
      <>
        <CookieConsent
          location="bottom"
          buttonText="Sure Thing"
          cookieName="HoweAndCo"
          style={{ background: "#000" }}
          buttonStyle={{
            color: "#000000",
            backgroundColor: "#0099DB",
            fontSize: "16px",
          }}
          expires={150}
          // onAccept={() => {
          //   sendPixelData();
          // }}
        >
          This website uses cookies to enhance the user experience.
          {/* <span style={{ fontSize: "10px" }}>
            This bit of text is smaller :O
          </span> */}
        </CookieConsent>
      </>
    </div>
  );
}

export default App;
