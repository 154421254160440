import React from "react";
import NavBar from "../Components/NavBar";
import MobileNavBar from "../Components/MobileNavBar";
import Footer from "../Components/Footer";
import { Link } from "react-router-dom";

const GdprPolicy = () => {
  return (
    <div className="bg-customBlue min-h-screen pb-10">
      <div>
        <div className="md:visible invisible">
          <NavBar />
        </div>
        <div className="md:invisible visible">
          <MobileNavBar />
        </div>
      </div>
      <div className=" md:px-32 px-8" style={{ paddingTop: 130 }}>
        <h1 className="text-black uppercase text-4xl  font-bold text-center">
          GDPR Policy
        </h1>
        <div className=" mt-4">
          <h2 className="md:text-2xl text-xl uppercase font-bold ">
            introduction
          </h2>
          <h2 className="leading-10 md:text-lg">
            We are committed to protecting your personal data and complying with
            the General Data Protection Regulation (GDPR). This policy explains
            how we collect, use, and safeguard your data.
          </h2>
        </div>
        <div className="md:mt-8 mt-6">
          <h2 className="md:text-2xl text-xl uppercase font-bold ">
            data controller
          </h2>
          <h2 className="leading-10 md:text-lg md:pt-2">
            The data controller responsible for your personal data is Howe & Co.
            Fish and Chips, with whom you can contact at
            info@howeandcofishandchips.co.uk.
          </h2>
        </div>

        <div className="md:mt-8 mt-6">
          <h2 className="md:text-2xl  text-xl uppercase font-bold ">
            data we collect
          </h2>
          <h2 className="leading-10 md:text-lg md:pt-2">
            We may collect the following types of personal data from you:
            Identification details (e.g., name, job title) Contact information
            (e.g., email address) Demographic information (e.g., postcode,
            preferences, interests) Usage data (e.g., how you use our website)
          </h2>
        </div>

        <div className="md:mt-8 mt-6">
          <h2 className="md:text-2xl  text-xl uppercase font-bold ">
            how we use your data
          </h2>
          <h2 className="leading-10 md:text-lg md:pt-2">
            We use your personal data for the following purposes: To provide and
            maintain our service To notify you about changes to our service To
            allow you to participate in interactive features of our service when
            you choose to do so To provide customer support To gather analysis
            or valuable information so that we can improve our service To
            monitor the usage of our service To detect, prevent, and address
            technical issues
          </h2>
        </div>

        <div className="md:mt-8 mt-6">
          <h2 className="md:text-2xl  text-xl uppercase font-bold ">
            legal basis for processing
          </h2>
          <h2 className="leading-10 md:text-lg md:pt-2">
            We process your personal data based on the following legal grounds:
            Your consent The performance of a contract with you Compliance with
            a legal obligation Our legitimate interests (e.g., improving our
            services, marketing)
          </h2>
        </div>

        <div className="md:mt-8 mt-6">
          <h2 className="md:text-2xl  text-xl uppercase font-bold ">
            your rights
          </h2>
          <h2 className="leading-10 md:text-lg md:pt-2">
            Under GDPR, you have the following rights: The right to access: You
            have the right to request copies of your personal data. The right to
            rectification: You have the right to request that we correct any
            information you believe is inaccurate. The right to erasure: You
            have the right to request that we erase your personal data under
            certain conditions. The right to restrict processing: You have the
            right to request that we restrict the processing of your personal
            data under certain conditions. The right to object to processing:
            You have the right to object to our processing of your personal data
            under certain conditions. The right to data portability: You have
            the right to request that we transfer the data that we have
            collected to another organization, or directly to you, under certain
            conditions.
          </h2>
        </div>

        <div className="md:mt-8 mt-6">
          <h2 className="md:text-2xl  text-xl uppercase font-bold ">
            data security
          </h2>
          <h2 className="leading-10 md:text-lg md:pt-2">
            We have implemented appropriate technical and organizational
            measures to ensure a level of security appropriate to the risk to
            your personal data. We protect your data from accidental or unlawful
            destruction, loss, alteration, unauthorized disclosure, or access.
          </h2>
        </div>

        <div className="md:mt-8 mt-6">
          <h2 className="md:text-2xl  text-xl uppercase font-bold ">
            data retention
          </h2>
          <h2 className="leading-10 md:text-lg md:pt-2">
            We will retain your personal data only for as long as is necessary
            for the purposes set out in this policy. We will retain and use your
            data to the extent necessary to comply with our legal obligations,
            resolve disputes, and enforce our agreements and policies.
          </h2>
        </div>

        <div className="md:mt-8 mt-6">
          <h2 className="md:text-2xl  text-xl uppercase font-bold ">
            changes to this policy
          </h2>
          <h2 className="leading-10 md:text-lg md:pt-2">
            We may update our GDPR policy from time to time. We will notify you
            of any changes by posting the new policy on this page. You are
            advised to review this policy periodically for any changes. Changes
            to this policy are effective when they are posted on this page.
          </h2>
        </div>

        <div className="md:mt-8 mt-6">
          <h2 className="md:text-2xl  text-xl uppercase font-bold ">
            contact us
          </h2>
          <h2 className="leading-10 md:text-lg md:pt-2">
            If you have any questions about this GDPR policy, please contact us
            at info@howeandcofishandchips.co.uk.
          </h2>
        </div>
      </div>
      <div>
        <Footer />
      </div>
      <div className="bg-customBlue flex  justify-center md:-mt-10 md:pb-8 pb-8">
        <Link
          to={"/privacy-policy"}
          className="hover:text-tan hover:cursor-pointer"
        >
          Privacy Policy
        </Link>
        <Link
          to={"/gdpr-policy"}
          className="hover:text-tan hover:cursor-pointer px-4"
        >
          GDPR Policy
        </Link>
      </div>
    </div>
  );
};

export default GdprPolicy;
