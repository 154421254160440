import React from "react";
import Box from "@mui/material/Box";

import Modal from "@mui/material/Modal";
import { useState } from "react";
import { motion } from "framer-motion";
import axios from "axios";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",

  bgcolor: "#0099dc",
  borderRadius: 5,
  boxShadow: 24,
  p: 4,
};
const ChipClubCTA = () => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [dateOfBirth, setDateOfBirth] = useState("");
  const [village, setVillage] = useState("");
  const [submitted, setSubmitted] = useState(false);

  async function handleSubmit() {
    const daysOfWeek = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    const currentDate = new Date();
    const currentDayOfWeek = daysOfWeek[currentDate.getDay()];
    try {
      const res = await axios.post("https://sheetdb.io/api/v1/55a5ngxsvn86v", {
        Date: new Date().toLocaleString(),
        FirstName: firstName,
        LastName: lastName,
        Email: email,
        Mobile: mobile,
        BirthDate: dateOfBirth,
        Village: village,
        Day: currentDayOfWeek,
      });
    } catch (err) {
      console.log(err);
    }
    handleClose();
  }
  return (
    <div
      id="chip-club"
      className="md:pt-48 md:pb-32 bg-tan md:py-0 pb-20 md:px-0 px-8"
    >
      <div className="flex flex-col items-center">
        <div className="flex flex-col items-center md:pt-0 pt-24">
          <motion.h4
            initial={{ x: -200, opacity: 0 }}
            whileInView={{ x: 0, opacity: 1 }}
            transition={{ type: "spring", delay: 0.2 }}
            viewport={{ once: true }}
            className="bg-customBlue w-fit md:text-7xl text-5xl uppercase font-Black text-[#000000] text-center px-2 md:px-10 pt-4 pb-2"
          >
            join the chip club
          </motion.h4>
          <motion.h4
            initial={{ x: 200, opacity: 0 }}
            whileInView={{ x: 0, opacity: 1 }}
            transition={{ type: "spring", delay: 0.2 }}
            viewport={{ once: true }}
            className="text-3xl text-center md:w-3/5 font-light mt-3"
          >
            Join our mailing list to receive chip van updates, reminders and
            exclusive discounts straight to your phone.
          </motion.h4>
        </div>
        <div className="flex mt-4">
          <div className="-mt-10">
            <img
              src="https://ik.imagekit.io/jsklle928/Howe%20and%20Co/Layer_1%20(3).png?updatedAt=1696363035973"
              alt="arrow"
              className="w-3/4"
            />
          </div>
          <motion.div
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{ delay: 0.4 }}
            viewport={{ once: true }}
            className="mt-4 -ml-4"
            onClick={handleOpen}
          >
            <button className="bg-customBlue text-3xl uppercase font-bold px-8 py-4 md:mx-10 text-tan rounded-md animate-bounce">
              Join the chip club
            </button>
          </motion.div>
          <div className="-mt-8">
            <img
              src="https://ik.imagekit.io/jsklle928/Howe%20and%20Co/Layer_1%20(3).png?updatedAt=1696363035973"
              alt="arrow"
              className="w-3/4 transform -scale-x-100"
            />
          </div>
        </div>
      </div>
      <>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <div className="flex flex-col items-center md:w-[650px] w-[375px]">
              <h4 className="md:text-2xl text-lg text-center capitalize leading-snug font-bold">
                Join our mailing list to receive chip van updates, reminders and
                exclusive discounts straight to your phone.{" "}
              </h4>
              <div className="flex w-full justify-center mt-4 md:flex-row flex-col items-center ">
                <div className="md:w-1/2 w-full mx-2">
                  <h5 className="text-tan text-xl">First Name</h5>
                  <input
                    onChange={(e) => {
                      setFirstName(e.target.value);
                    }}
                    className="w-full mt-2 h-10 px-2 rounded-md bg-black bg-opacity-20 text-zinc-800 font-medium placeholder:text-zinc-800"
                    placeholder="Enter your First Name"
                  ></input>
                </div>
                <div className="md:w-1/2 w-full mx-2 md:mt-0 mt-3">
                  <h5 className="text-tan text-xl">Last Name</h5>
                  <input
                    onChange={(e) => {
                      setLastName(e.target.value);
                    }}
                    className="w-full mt-2 h-10 px-2 rounded-md bg-black bg-opacity-20 text-zinc-800 font-medium placeholder:text-zinc-800 "
                    placeholder="Enter your Last Name"
                  ></input>
                </div>
              </div>

              <div className="flex w-full justify-center mt-4 md:flex-row flex-col items-center">
                <div className="md:w-1/2 w-full mx-2 ">
                  <h5 className="text-tan text-xl">Email Address</h5>
                  <input
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                    className="w-full mt-2 h-10 px-2 rounded-md bg-black bg-opacity-20 text-zinc-800 font-medium placeholder:text-zinc-800"
                    placeholder="Enter your Email Address"
                  ></input>
                </div>
                <div className="md:w-1/2 w-full mx-2 md:mt-0 mt-3">
                  <h5 className="text-tan text-xl">Mobile Phone</h5>
                  <input
                    onChange={(e) => {
                      setMobile(e.target.value);
                    }}
                    className="w-full mt-2 h-10 px-2 rounded-md bg-black bg-opacity-20 text-zinc-800 font-medium placeholder:text-zinc-800 "
                    placeholder="Enter your Mobile Phone"
                  ></input>
                </div>
              </div>
              <div className="flex w-full justify-center mt-4 md:flex-row flex-col items-center">
                <div className="md:w-1/2 w-full mx-2 ">
                  <h5 className="text-tan text-xl">D.O.B.</h5>
                  <input
                    onChange={(e) => {
                      setDateOfBirth(e.target.value);
                    }}
                    className="w-full mt-2 h-10 px-2 rounded-md bg-black bg-opacity-20 text-zinc-800 font-medium placeholder:text-zinc-800"
                    placeholder="Enter your D.O.B."
                  ></input>
                </div>
                <div className="md:w-1/2 w-full mx-2 md:mt-0 mt-3">
                  <h5 className="text-tan text-xl">Village</h5>
                  <select
                    onInput={(e) => {
                      setVillage(e.target.value);
                    }}
                    className="w-full mt-2 h-10 px-2 rounded-md bg-black bg-opacity-20 text-zinc-800 font-medium placeholder:text-zinc-800 "
                    placeholder="Enter your Village"
                  >
                    <option>Wallingford</option>
                    <option>Watlington</option>
                    <option>Lewknor</option>
                    <option>Thame</option>
                    <option>Cholsey</option>
                    <option>Lakes Estate</option>
                    <option>Bolters End</option>
                    <option>Eynsham</option>
                    <option>High Wycombe</option>
                    <option>Lacey Green</option>
                    <option>Lane End</option>
                    <option>Littleworth Park</option>
                    <option>Marlow</option>
                    <option>Naphill</option>
                    <option>Piddington</option>
                    <option>Risborough</option>
                    <option>Sandy</option>
                    <option>Stokenchurch</option>
                    <option>Walters Ash</option>
                    <option>Wheatley</option>
                    <option>Other</option>
                  </select>
                </div>
              </div>
              <div>
                <button
                  className="uppercase text-2xl text-white bg-black px-20 py-3 rounded-lg mt-6"
                  onClick={handleSubmit}
                >
                  submit
                </button>
              </div>
            </div>
          </Box>
        </Modal>
      </>
    </div>
  );
};

export default ChipClubCTA;
