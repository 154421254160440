import React from "react";
import Logo from "../Images/LOGO 1.png";

const Footer = () => {
  return (
    <div className="bg-customBlue w-full py-8 px-12 ">
      <div className="flex md:flex-row flex-col w-full justify-between items-center md:flex-nowrap flex-wrap">
        <div>
          <img src={Logo} alt="logo" />
        </div>
        <div className="capitalize flex md:flex-row flex-col items-center md:w-2/4 justify-around text-xl font-medium md:flex-nowrap flex-wrap md:mt-0 mt-4 ">
          {/* <h4
            className="hover:cursor-pointer hover:text-tan md:mt-0 mt-2"
            onClick={() => {
              const section = document.getElementById("tracker");
              if (section) section.scrollIntoView({ behavior: "smooth" });
            }}
          >
            chippy tracker
          </h4> */}

          <h4
            className="hover:cursor-pointer hover:text-tan md:mt-0 mt-2"
            onClick={() => {
              const section = document.getElementById("chip-club");
              if (section) section.scrollIntoView({ behavior: "smooth" });
            }}
          >
            chip club{" "}
          </h4>
          <h4
            className="hover:cursor-pointer hover:text-tan md:mt-0 mt-2"
            onClick={() => {
              const section = document.getElementById("catering");
              if (section) section.scrollIntoView({ behavior: "smooth" });
            }}
          >
            catering
          </h4>
          <h4
            className="hover:cursor-pointer hover:text-tan md:mt-0 mt-2 uppercase"
            onClick={() => {
              const section = document.getElementById("faq");
              if (section) section.scrollIntoView({ behavior: "smooth" });
            }}
          >
            faq
          </h4>
          <h4 className="hover:cursor-pointer hover:text-tan md:mt-0 mt-2">
            📞 Van 22: 07376395678
          </h4>
          <h4 className="hover:cursor-pointer hover:text-tan md:mt-0 mt-2">
            📞 Van 55: 07494235366
          </h4>
          {/* <h4 className="hover:cursor-pointer hover:text-tan md:mt-0 mt-2">
            merch
          </h4> */}
        </div>
        <div className="flex items-center md:mt-0 mt-4 ">
          <img
            src="https://ik.imagekit.io/jsklle928/Howe%20and%20Co/social%20icons/mail-04.png?updatedAt=1696426622252"
            alt="mail icon"
            className="h-6 mx-2 hover:h-10"
          />
          <a
            href="https://www.tiktok.com/@thachipman?_t=8gcru38yPKa&_r=1"
            target="blank"
          >
            <img
              src="https://ik.imagekit.io/jsklle928/Howe%20and%20Co/social%20icons/tik%20tok-01.png?updatedAt=1696426622327"
              alt="tik tok logo"
              className="h-6 mx-2 hover:h-10"
            />
          </a>

          <a
            href="https://instagram.com/howeandco22?igshid=MzMyNGUyNmU2YQ%3D%3D&utm_source=qr"
            target="blank"
          >
            <img
              src="https://ik.imagekit.io/jsklle928/Howe%20and%20Co/social%20icons/instagram-02.png?updatedAt=1696426622325"
              alt="instagram logo"
              className="h-6 mx-2 hover:h-10"
            />
          </a>
          <a href="https://www.facebook.com/mobilechippy" target="blank">
            <img
              src="https://ik.imagekit.io/jsklle928/Howe%20and%20Co/social%20icons/facebook-03.png?updatedAt=1696426622360"
              alt="facebook logo"
              className="h-6 mx-2 hover:h-10"
            />
          </a>
        </div>
      </div>
    </div>
  );
};

export default Footer;
